// Component states
import states from './settings-divider-modifier.state.js'

// Components
import custColorPicker from '@/components/standalone/cust-color-picker/Cust-color-picker.vue'

/**
 * Vue declaration ------------------------------------
 */

// Name
const name = 'Settings-mj-divider'

// Properties
const props = {
  mjml: Object
}

// Data
const data = function () {
  return {
    states
  }
}

// Vue@Components
const components = {
  custColorPicker
}
// Vue@Components

// Methods
const computed = {

  /**
   * Divider Width
   * @type {Object}
   */
  width: {
    // getter
    get () {
      return parseInt(this.mjml.attributes.width, 10)
    },
    // setter
    set (newValue) {
      this.$set(this.mjml.attributes, 'width', `${newValue}%`)
    }
  },

  /**
   * Divider border size
   * @type {Object}
   */
  size: {
    // getter
    get () {
      return parseInt(this.mjml.attributes['border-width'], 10)
    },
    // setter
    set (newValue) {
      this.$set(this.mjml.attributes, 'border-width', `${newValue}px`)
    }
  }
}

// Vue component syntax
export default {
  name,
  data,
  props,
  computed,
  components
}
