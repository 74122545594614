export default {
  settings: {
    styles: [
      {
        label: 't_solid_',
        value: 'solid'
      },
      {
        label: 't_dotted_',
        value: 'dotted'
      },
      {
        label: 't_dashed_',
        value: 'dashed'
      }
    ]
  }
}
